import React, { Fragment } from 'react';
import { Divider } from '@mui/material';
import { MenuGroup } from './MenuGroup';
import { MenuItem } from './MenuItem';
import { MenuList } from './MenuList';
import { TMenuList, TMenuItem } from './types';
import useAuth from 'hooks/useAuth';
import { isMenuItemAvailable } from '../utils';

export const MainMenu = ({}) => {
  const { user } = useAuth();

  const MENU_LIST = MenuList();

  const isOpen = true;

  return (
    <Fragment>
      {MENU_LIST.map((menu: TMenuList) => {
        const hasPermission = menu.permissions?.some((permission) => isMenuItemAvailable(permission, user.permissions));

        if (!hasPermission) return null;

        return (
          <Fragment key={menu.header}>
            <MenuGroup title={menu.header}>
              {menu.items.map((item: TMenuItem) => {
                const hasPermission = isMenuItemAvailable(item.permission, user.permissions);

                if (!hasPermission) return null;

                return <MenuItem key={item.id} title={item.id} icon={item.icon} isOpen={isOpen} href={item.href} extra={item.extra} />;
              })}
            </MenuGroup>
            <Divider />
          </Fragment>
        );
      })}
    </Fragment>
  );
};
