import { TPermissionRoute } from 'interfaces/permissions';

export const PERMISSION_CREATE_META_ADS = 'createMetaAds';
export const PERMISSION_WEBHOOK = 'webhooks';
export const PERMISSION_FACEBOOK_INTEGRATION = 'facebookIntegration';
export const PERMISSION_CONVERSATION = 'conversations';
export const PERMISSION_META_ADS = 'metaAds';
export const PERMISSION_META_INSIGHTS = 'metaInsights';
export const PERMISSION_INSTAGRAM = 'instagram';
export const PERMISSION_GOOGLE_ADS = 'googleAds';
export const PERMISSION_YOUTUBE = 'youtube';
export const PERMISSION_TIKTOK = 'tiktok';
export const PERMISSION_CALENDAR = 'calendar';
export const PERMISSION_SETTINGS = 'settings';
export const PERMISSION_LEADS = 'leads';
export const PERMISSION_EVENTS = 'events';
export const PERMISSION_TASKS = 'tasks';
export const PERMISSION_NOTES = 'notes';
export const PERMISSION_MEDIAS = 'medias';
export const PERMISSION_POCKETS = 'pockets';
export const PERMISSION_HISTORY = 'history';
export const PERMISSION_CHARTS_LEADS = 'leadsCharts';
export const PERMISSION_CHARTS_LEADS_BY_SOURCES = 'leadsBySourcesCharts';
export const PERMISSION_CHARTS_LEADS_BY_TAGS = 'leadsByTagsCharts';

export const PERMISSIONS_ARRAY = [
  PERMISSION_CALENDAR,
  PERMISSION_CONVERSATION,
  PERMISSION_CREATE_META_ADS,
  PERMISSION_FACEBOOK_INTEGRATION,
  PERMISSION_SETTINGS,
  PERMISSION_WEBHOOK,
  PERMISSION_LEADS,
  PERMISSION_EVENTS,
  PERMISSION_TASKS,
  PERMISSION_POCKETS,
  PERMISSION_NOTES,
  PERMISSION_HISTORY,
  PERMISSION_MEDIAS,
  PERMISSION_CHARTS_LEADS,
  PERMISSION_CHARTS_LEADS_BY_SOURCES,
  PERMISSION_CHARTS_LEADS_BY_TAGS
];

export const PERMISSIONS: TPermissionRoute[] = [
  {
    title: 'conversations',
    children: [
      {
        title: 'conversations',
        permission: PERMISSION_CONVERSATION
      }
    ]
  },
  {
    title: 'analytics',
    children: [
      {
        title: 'meta-ads',
        permission: PERMISSION_META_ADS
      },
      {
        title: 'meta-insights',
        permission: PERMISSION_META_INSIGHTS
      },
      {
        title: 'instagram',
        permission: PERMISSION_INSTAGRAM
      },
      {
        title: 'google-ads',
        permission: PERMISSION_GOOGLE_ADS
      },
      {
        title: 'youtube',
        permission: PERMISSION_YOUTUBE
      },
      {
        title: 'tiktok',
        permission: PERMISSION_TIKTOK
      }
    ]
  },
  {
    title: 'crm',
    children: [
      {
        title: 'Permissions.Crm.Leads',
        permission: PERMISSION_LEADS
      },
      {
        title: 'Permissions.Crm.Events',
        permission: PERMISSION_EVENTS
      },
      {
        title: 'Permissions.Crm.Tasks',
        permission: PERMISSION_TASKS
      },
      {
        title: 'Permissions.Crm.Notes',
        permission: PERMISSION_NOTES
      },
      {
        title: 'Permissions.Crm.Medias',
        permission: PERMISSION_MEDIAS
      },
      {
        title: 'Permissions.Crm.History',
        permission: PERMISSION_HISTORY
      }
    ]
  },
  {
    title: 'Menu.Reports',
    children: [
      {
        title: 'Menu.LeadsCharts',
        permission: PERMISSION_CHARTS_LEADS
      },
      {
        title: 'Menu.LeadsBySourcesCharts',
        permission: PERMISSION_CHARTS_LEADS_BY_SOURCES
      },
      {
        title: 'Menu.LeadsByTagsCharts',
        permission: PERMISSION_CHARTS_LEADS_BY_TAGS
      }
    ]
  },
  {
    title: 'calendar',
    children: [
      {
        title: 'calendar',
        permission: PERMISSION_CALENDAR
      }
    ]
  },
  {
    title: 'meta-ads',
    children: [
      {
        title: 'module',
        permission: PERMISSION_CREATE_META_ADS
      }
    ]
  },
  {
    title: 'integrations',
    children: [
      {
        title: 'webhooks',
        permission: PERMISSION_WEBHOOK
      },
      {
        title: 'fb-integration',
        permission: PERMISSION_FACEBOOK_INTEGRATION
      },
      {
        title: 'pockets',
        permission: PERMISSION_POCKETS
      }
    ]
  },
  {
    title: 'settings',
    children: [
      {
        title: 'settings',
        permission: PERMISSION_SETTINGS
      }
    ]
  }
];
