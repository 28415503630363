import { isPermissionGranted } from 'utils/isPermissionGranted';
import { TPermission } from 'interfaces/permissions';
import { READ_PERMISSION } from 'constants/user';

/**
 * Will check if the menu item is available based on the permissions
 *
 * @param {TPermission} permission
 * @param {TPermission[]} permissions
 * @return {*}
 */
export const isMenuItemAvailable = (permission: TPermission | undefined, permissions: TPermission[]) => {
  if (!permission) return true;

  return isPermissionGranted({
    permissions: permissions,
    permission: READ_PERMISSION,
    pathname: permission
  });
};
