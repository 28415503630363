import React from 'react';
import { alpha, Box, Typography } from '@mui/material';
import { IMenuLabel } from './types';

export const LabelItem = ({ item }: IMenuLabel) => (
  <Box
    style={{
      backgroundColor: alpha(item.color, 0.08),
      paddingLeft: 7,
      paddingRight: 7,
      paddingTop: 5,
      paddingBottom: 5,
      borderRadius: 8
    }}
  >
    <Typography textTransform="capitalize" fontSize={10} color={item.color}>
      {item.value}
    </Typography>
  </Box>
);
