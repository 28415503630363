import React, { useState } from 'react';
import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import { Box, Collapse, List, Typography } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import { IMenuGroup } from './types';

export const MenuGroup = ({ children, title }: IMenuGroup) => {
  const [isOpen, setOpen] = useState(true);

  const { translate } = useTranslation();

  return (
    <List
      subheader={
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          onClick={() => setOpen(!isOpen)}
          sx={{
            px: 2,
            py: 1,
            pt: 2,
            cursor: 'pointer'
          }}
        >
          {isOpen ? <ArrowDropDown style={{ width: 18, height: 18 }} /> : <ArrowRight style={{ width: 18, height: 18 }} />}
          <Typography
            variant="caption"
            sx={{
              fontSize: '0.875rem',
              fontWeight: 500,
              color: (theme) => (theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[900]),
              textTransform: 'capitalize'
            }}
          >
            {translate(`Menu.${title}`)}
          </Typography>
        </Box>
      }
    >
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </List>
  );
};
