import { alpha, Badge, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { IMenuItem } from './types';
import { useTranslation } from 'hooks/useTranslation';
import { useRouter } from 'next/router';
import { LabelItem } from './MenuLabel';

export const MenuItem = ({ title, icon, extra, isOpen = true, href, ...props }: IMenuItem) => {
  const { sx, ...rest } = props;

  const router = useRouter();

  const { asPath } = router;

  const Icon = icon;

  const Wrapper = isOpen ? Fragment : Tooltip;

  const BadgeWrapper = isOpen ? Fragment : extra?.showBadge ? Badge : Fragment;

  const { translate } = useTranslation();

  const handleRedirect = (href: string) => router.push(href);

  const isSelected = asPath === href;

  return (
    <Wrapper title={title} placement="right">
      <ListItem key={title} sx={{ px: 0.5, py: 0.25 }}>
        <ListItemButton
          sx={{
            backgroundColor: (theme) => (isSelected ? theme.palette.primary.light : 'transparent'),
            minHeight: 48,
            px: 2.5,
            borderRadius: '10px',
            justifyContent: isOpen ? 'initial' : 'center',
            ...sx
          }}
          onClick={() => handleRedirect(href)}
          {...rest}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              justifyContent: 'center',
              color: (theme) => (isSelected ? theme.palette.primary.main : theme.palette.text.primary),
              mr: isOpen ? 3 : 'auto'
            }}
          >
            <BadgeWrapper
              badgeContent={extra?.value}
              sx={{
                '.MuiBadge-badge': {
                  backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
                  color: (theme) => theme.palette.primary.main
                }
              }}
            >
              {Icon}
            </BadgeWrapper>
          </ListItemIcon>
          <ListItemText
            sx={{
              opacity: isOpen ? 1 : 0
            }}
            primary={
              <Typography
                fontWeight={isSelected ? 500 : 400}
                color={(theme) => (isSelected ? theme.palette.primary.main : theme.palette.text.primary)}
              >
                {translate(`Menu.${title}`)}
              </Typography>
            }
          />
          {isOpen && extra?.showLabel && <LabelItem item={extra} />}
        </ListItemButton>
      </ListItem>
    </Wrapper>
  );
};
