import React from 'react';
import { useTheme } from '@mui/material';
import {
  IconBrandFacebook,
  IconBrandGoogle,
  IconBrandInstagram,
  IconBrandMeta,
  IconBrandPocket,
  IconBrandTiktok,
  IconBrandWhatsapp,
  IconBrandYoutube,
  IconCalendar,
  IconChartArcs,
  IconChartBar,
  IconDatabase,
  IconDeviceDesktop,
  IconHomeStar,
  IconId,
  IconMessages,
  IconSettings,
  IconUsers,
  IconWebhook
} from '@tabler/icons';
import {
  PERMISSION_CALENDAR,
  PERMISSION_CHARTS_LEADS_BY_TAGS,
  PERMISSION_CHARTS_LEADS_BY_SOURCES,
  PERMISSION_CHARTS_LEADS,
  PERMISSION_CONVERSATION,
  PERMISSION_FACEBOOK_INTEGRATION,
  PERMISSION_GOOGLE_ADS,
  PERMISSION_INSTAGRAM,
  PERMISSION_LEADS,
  PERMISSION_META_ADS,
  PERMISSION_META_INSIGHTS,
  PERMISSION_POCKETS,
  PERMISSION_SETTINGS,
  PERMISSION_TIKTOK,
  PERMISSION_WEBHOOK,
  PERMISSION_YOUTUBE
} from 'constants/permissions';
import {
  ROUTE_CALENDAR,
  ROUTE_CONVERSATIONS,
  ROUTE_GOOGLE_ADS,
  ROUTE_INSTAGRAM,
  ROUTE_LEADS,
  ROUTE_META_ADS,
  ROUTE_META_INSIGHTS,
  ROUTE_META_LEADS_ADS,
  ROUTE_POCKETS,
  ROUTE_TIKTOK,
  ROUTE_WEBHOOKS,
  ROUTE_YOUTUBE,
  ROUTE_SETTINGS_MY_TEAM,
  ROUTE_SETTINGS_USERS,
  ROUTE_SETTINGS_TEAM_AD_ACCOUNTS,
  ROUTE_SETTINGS_LEADS,
  ROUTE_SETTINGS_SOURCES,
  ROUTE_SETTINGS_WHATSAPP,
  ROUTE_SETTINGS,
  ROUTE_REPORTS_LEADS,
  ROUTE_REPORTS_SOURCES,
  ROUTE_REPORTS_TAGS
} from 'constants/routes';
import { IconArrowRightToArc } from '@tabler/icons-react';
import useConversation from 'hooks/useConversation';
import { TMenuList } from './types';

const ICON_STYLE = { width: 18, height: 18 };

export const MenuList = () => {
  const theme = useTheme();

  const { countNoRead } = useConversation();

  const MENU_LAYOUT: TMenuList[] = [
    {
      header: 'management',
      permissions: [PERMISSION_LEADS, PERMISSION_CONVERSATION, PERMISSION_CALENDAR],
      items: [
        {
          id: 'CRM',
          icon: <IconDatabase style={ICON_STYLE} />,
          permission: PERMISSION_LEADS,
          href: ROUTE_LEADS
        },
        {
          id: 'conversations',
          icon: <IconMessages style={ICON_STYLE} />,
          permission: PERMISSION_CONVERSATION,
          href: ROUTE_CONVERSATIONS,
          extra: {
            value: countNoRead,
            color: theme.palette.primary.main,
            showBadge: countNoRead > 0,
            showLabel: countNoRead > 0
          }
        },
        {
          id: 'calendar',
          icon: <IconCalendar style={ICON_STYLE} />,
          permission: PERMISSION_CALENDAR,
          href: ROUTE_CALENDAR
        }
      ]
    },
    {
      header: 'Reports',
      permissions: [PERMISSION_CHARTS_LEADS, PERMISSION_CHARTS_LEADS_BY_SOURCES, PERMISSION_CHARTS_LEADS_BY_TAGS],
      items: [
        {
          id: 'LeadsCharts',
          icon: <IconChartBar style={ICON_STYLE} />,
          permission: PERMISSION_CHARTS_LEADS,
          href: ROUTE_REPORTS_LEADS
        },
        {
          id: 'LeadsBySourcesCharts',
          icon: <IconChartBar style={ICON_STYLE} />,
          permission: PERMISSION_CHARTS_LEADS_BY_SOURCES,
          href: ROUTE_REPORTS_SOURCES
        },
        {
          id: 'LeadsByTagsCharts',
          icon: <IconChartBar style={ICON_STYLE} />,
          permission: PERMISSION_CHARTS_LEADS_BY_TAGS,
          href: ROUTE_REPORTS_TAGS
        }
      ]
    },
    {
      header: 'automation',
      permissions: [PERMISSION_WEBHOOK, PERMISSION_FACEBOOK_INTEGRATION, PERMISSION_POCKETS],
      items: [
        {
          id: 'webhooks',
          icon: <IconWebhook style={ICON_STYLE} />,
          permission: PERMISSION_WEBHOOK,
          href: ROUTE_WEBHOOKS
        },
        {
          id: 'fb-integrations',
          icon: <IconBrandFacebook style={ICON_STYLE} />,
          permission: PERMISSION_FACEBOOK_INTEGRATION,
          href: ROUTE_META_LEADS_ADS
        },
        {
          id: 'pockets',
          icon: <IconBrandPocket style={ICON_STYLE} />,
          permission: PERMISSION_POCKETS,
          href: ROUTE_POCKETS
        }
      ]
    },
    {
      header: 'analytics',
      permissions: [
        PERMISSION_META_ADS,
        PERMISSION_META_INSIGHTS,
        PERMISSION_INSTAGRAM,
        PERMISSION_GOOGLE_ADS,
        PERMISSION_YOUTUBE,
        PERMISSION_TIKTOK
      ],
      items: [
        {
          id: 'meta-ads',
          icon: <IconBrandMeta style={ICON_STYLE} />,
          permission: PERMISSION_META_ADS,
          href: ROUTE_META_ADS
        },
        {
          id: 'meta-insights',
          icon: <IconChartArcs style={ICON_STYLE} />,
          permission: PERMISSION_META_INSIGHTS,
          href: ROUTE_META_INSIGHTS
        },
        {
          id: 'instagram',
          icon: <IconBrandInstagram style={ICON_STYLE} />,
          permission: PERMISSION_INSTAGRAM,
          href: ROUTE_INSTAGRAM
        },
        {
          id: 'google-ads',
          icon: <IconBrandGoogle style={ICON_STYLE} />,
          permission: PERMISSION_GOOGLE_ADS,
          href: ROUTE_GOOGLE_ADS
        },
        {
          id: 'youtube',
          icon: <IconBrandYoutube style={ICON_STYLE} />,
          permission: PERMISSION_YOUTUBE,
          href: ROUTE_YOUTUBE
        },
        {
          id: 'tiktok-ads',
          icon: <IconBrandTiktok style={ICON_STYLE} />,
          permission: PERMISSION_TIKTOK,
          href: ROUTE_TIKTOK
        }
      ]
    },
    {
      header: 'settings',
      permissions: [PERMISSION_SETTINGS],
      items: [
        {
          id: 'settings',
          icon: <IconSettings style={ICON_STYLE} />,
          href: ROUTE_SETTINGS
        }
      ]
    }
  ];

  return MENU_LAYOUT;
};
