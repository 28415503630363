import React, { Fragment } from 'react';
import { MenuItem } from './MenuItem';
import { MenuList } from './MenuList';
import { isMenuItemAvailable } from '../utils';
import useAuth from 'hooks/useAuth';
import { TMenuItem, TMenuList } from './types';

export const MinimalistMenu = () => {
  const { user } = useAuth();

  const MENU_LIST = MenuList();

  return (
    <Fragment>
      {MENU_LIST.map((menu: TMenuList) =>
        menu.items.map((item: TMenuItem) => {
          const hasPermission = isMenuItemAvailable(item.permission, user.permissions);

          if (!hasPermission) return null;

          return <MenuItem title={item.id} icon={item.icon} isOpen={false} href={item.href} extra={item.extra} />;
        })
      )}
    </Fragment>
  );
};
