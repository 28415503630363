import { TIsPermmisionGranted } from './types';

/**
 * It will check if the user has the required permission for the current route.
 * If permission is not granted, a redirect to a different route will be triggered.
 *
 * @param {*} { permissions, permission, pathname, redirect }
 * @return {*} boolean
 */
export const isPermissionGranted = ({ permissions, permission, pathname, redirect }: TIsPermmisionGranted) => {
  const parsePathname = (path: string) => path.replace(/\/\[\w+\]/, '');

  if (!permissions) return false;

  const route = parsePathname(pathname);

  if (permissions?.[route]?.includes(permission)) return true;

  if (redirect) {
    redirect();
    return false;
  }
};
